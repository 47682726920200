import React, { Component } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import Layout from "../components/layout";
import { Breadcrumbs, Product, Button } from "../components/styleguide";
import queryString from "query-string";
import { createSlug } from "../../utils/constants.js";
import { navigate } from "gatsby";
import Filters from "../components/filters";
import MarkdownRenderer from "react-markdown-renderer";

const static_limit = 15;

class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: false,
      limit: 15
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ filtersOpen: false });
    }
  }

  showMore = () => this.setState({ limit: this.state.limit + static_limit });

  getSearch = () =>
    queryString.parse(this.props.location.search, { arrayFormat: "bracket" });

  filterGrid = grid => {
    let newGrid = grid;
    const search = this.getSearch();

    if (search.brand) {
      newGrid = newGrid.filter(({ brand }) => search.brand.includes(brand));
    }
    if (search.fabric) {
      newGrid = newGrid.filter(({ fabric }) => search.fabric.includes(fabric));
    }
    return newGrid;
  };

  handleFilterAction = (type, value) => {
    const { pathname } = this.props.location;
    let newSearch = this.getSearch();
    if (!newSearch[type]) {
      newSearch[type] = [];
      newSearch[type].push(String(value));
    } else {
      const index = newSearch[type].find(
        item => String(item) === String(value)
      );
      if (index) {
        delete newSearch[type][newSearch[type].indexOf(index)];
      } else {
        newSearch[type].push(String(value));
      }
    }
    const newSearchPath = queryString.stringify(newSearch, {
      arrayFormat: "bracket"
    });

    return navigate(`${pathname}?${newSearchPath}`);
  };
  render() {
    const {
      data,
      common,
      breadcrumbs,
      productsPath,
      categories,
      brands,
      fabrics
    } = this.props.pageContext;
    const { filtersOpen, limit } = this.state;
    const { cookies, location } = this.props;
    const linkCategories = categories.map(category => ({
      ...category,
      link: `${productsPath}/${createSlug(category.name)}`
    }));
    const grid = this.props.pageContext.products;
    return (
      <CookiesProvider>
        <Layout
          data={common}
          title={
            data.seoTitle && data.seoTitle.length > 0
              ? data.seoTitle
              : `${data.name} - DMV-PLUS`
          }
          description={
            data.seoDescription && data.seoDescription.length > 0
              ? data.seoDescription
              : common.static_data[0].category_seo_description.replace(
                  "$category",
                  data.name.toLowerCase()
                )
          }
          hrefLang={data.hrefLang}
        >
          <div className="main-container">
            <Breadcrumbs lang={common.lang} data={breadcrumbs} />
            <section>
              <header className="section-header">
                <h1>
                  <strong>{data.name}</strong>
                </h1>
                <MarkdownRenderer
                  className="markdown"
                  markdown={data.description}
                />
              </header>
            </section>
            <div className="filters-trigerrer">
              <button
                className="btn-filters"
                onClick={() =>
                  this.setState(({ filtersOpen }) => ({
                    filtersOpen: !filtersOpen
                  }))
                }
              >
                <span>{common.translations[0].filters}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.5"
                  height="17"
                  viewBox="0 0 20.5 17"
                >
                  <g id="Group_635" transform="translate(-208.5 -1117)">
                    <line
                      id="Line_14"
                      x2="20"
                      transform="translate(208.5 1119.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_15"
                      x2="20"
                      transform="translate(208.5 1125.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_16"
                      x2="20"
                      transform="translate(208.5 1131.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"
                    />
                    <circle
                      id="Ellipse_3"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(224 1117)"
                      fill="#fff"
                    />
                    <circle
                      id="Ellipse_4"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(211 1123)"
                      fill="#fff"
                    />
                    <circle
                      id="Ellipse_5"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(216 1129)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </button>
            </div>
            <section className="products-wrapper">
              <Filters
                categories={linkCategories}
                translations={common.translations[0]}
                activeCategory={location.pathname
                  .replace(`${productsPath}/`, "")
                  .replace("/", "")}
                productsPath={productsPath}
                fabrics={fabrics}
                brands={brands}
                filterAction={this.handleFilterAction}
                getSearch={this.getSearch}
                open={filtersOpen}
                setWrapperRef={this.setWrapperRef}
                hideFilters={() =>
                  this.setState(({ filtersOpen }) => ({
                    filtersOpen: !filtersOpen
                  }))
                }
              />
              <div className="grid x3">
                {grid &&
                  this.filterGrid(grid)
                    .slice(0, limit)
                    .map(product => (
                      <Product
                        onClick={() =>
                          cookies.set(
                            "listing-href",
                            `${location.pathname}${location.search}`,
                            { path: "/" }
                          )
                        }
                        key={product.id}
                        className="fade-in"
                        data={product}
                        productPage
                      />
                    ))}
                {(!grid || (grid && this.filterGrid(grid).length < 1)) && (
                  <p className="grid-empty">Brak produktów</p>
                )}
                {grid && limit < this.filterGrid(grid).length && (
                  <div className="show-more-wrapper">
                    <Button
                      type="button"
                      content={common.translations[0].show_more}
                      fill
                      size="lg"
                      onClick={() => this.showMore()}
                    />
                  </div>
                )}
              </div>
            </section>
          </div>
        </Layout>
      </CookiesProvider>
    );
  }
}

export default withCookies(Listing);
