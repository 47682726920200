import React from "react";
import { Checkbox, Button } from "../components/styleguide";
import { createSlug } from "../../utils/constants.js";
import { Link } from "gatsby";

const Filters = ({
  activeCategory,
  categories,
  open,
  brands,
  fabrics,
  setWrapperRef,
  filterAction,
  getSearch,
  translations,
  hideFilters,
  productsPath
}) => {
  return (
    <aside
      className={`filters-panel ${open ? "open" : ""}`}
      ref={setWrapperRef}
    >
      <div className="filter-box">
        <h3 className="filter-box--title">{translations.category}</h3>
        <ul className="filter-box--options">
          {categories.map(({ id, name, link }) => (
            <li key={id}>
              <Link
                className={activeCategory === createSlug(name) ? "active" : ""}
                to={activeCategory === createSlug(name) ? productsPath : link}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="filter-box">
        <h3 className="filter-box--title">{translations.material}</h3>
        <ul className="filter-box--options">
          {fabrics.map(({ id, name }) => (
            <li key={name}>
              <Checkbox
                id={name}
                label={name}
                checked={
                  getSearch()["fabric"]
                    ? getSearch()["fabric"].find(
                        item => String(item) === String(name)
                      )
                    : false
                }
                onChange={() => filterAction("fabric", name)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="filter-box">
        <h3 className="filter-box--title">{translations.brand}</h3>
        <ul className="filter-box--options">
          {brands.map(({ id, name }) => (
            <li key={name}>
              <Checkbox
                id={name}
                label={name}
                checked={
                  getSearch()["brand"]
                    ? getSearch()["brand"].find(
                        item => String(item) === String(name)
                      )
                    : false
                }
                onChange={() => filterAction("brand", name)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="buttons-holder">
        <Button
          type="button"
          content={translations.set}
          fill
          size="sm"
          onClick={() => hideFilters()}
        />
        <Button
          type="link"
          content={translations.clear}
          href={productsPath}
          fill
          size="sm"
        />
      </div>
    </aside>
  );
};

export default Filters;
